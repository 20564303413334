import React, {Component, Suspense} from "react";
import axios from "axios";
import Input from "./Input/Input";
import Agreement1 from "./Agreements/Agreement1";
import Agreement2 from "./Agreements/Agreement2";
import ThankYou from "../ThankYou/ThankYou";
// import FormTrackingCodes from "../../services/formTrackingCodes";
import {ReCaptcha} from "react-recaptcha-v3";
import path from "../../config/api.config";
import * as validators from "../../utils/validators";
import DuplicityError from "../DuplicityError/DuplicityError";
import {withTranslation} from "react-i18next";
import conf from "../../config/config";
import formConfig from "../../config/form";
import i18next from "i18next";
import config from "../../config/config";
import Autocomplete from "./Autocomplete/Autocomplete";
import TagManager from "react-gtm-module";

class Form extends Component {
    // formTrackingCode = data => {
    //     console.log("**********************************************");
    //     console.log("formTrackingCode");
    //     console.log("**********************************************");
    //     FormTrackingCodes.trackLeadForm({data: data, typeForm: this.getTypeForm()});
    // };

    getSearchInfo = (search) => {
        return new URLSearchParams(window.location.search).get(search) || "";
    };

    maxDate = () => {
        let year = new Date();
        year.setFullYear(year.getFullYear() - 16); // min age set to 16
        return year;
    };

    getTypeForm = () => {

        return "join.avon.cz";
    };

    componentDidMount() {
        const tagManagerArgs = {
            gtmId: "GTM-T683FPH"
        };

        TagManager.initialize(tagManagerArgs);
    }

    state = {
        recaptchaToken: "",
        error: false,
        duplicityError: false,
        responseSuccess: false,
        uplineEmail: "",
        appSrcCode: "51",
        src_r: this.getSearchInfo("src_r"), // redirect from social prospecting default page
        utm_term: this.getSearchInfo("utm_term"),
        inputs: formConfig.inputs,
        checkboxes: formConfig.checkboxes,
        isFormSent: false
    };

    // validators

    validateForm = () => {
        let inputs = [...this.state.inputs];
        let isValid = true;

        // eslint-disable-next-line array-callback-return
        inputs.map((input, index) => {
            const inputId = input.id;
            if (input.required) {
                if (inputId === "Phone") {
                }

                switch (inputId) {
                    case "firstName":
                    case "lastName":
                        if (!validators.validateNameValue(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "Email":
                        if (!validators.validateEmail(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "Phone":
                        if (!validators.validatePhone(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "Street":
                        if (!validators.validateStreetName(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "StreetNr":
                        if (!validators.validateStreetNo(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "PostCode":
                        if (!validators.validatePostCode(input.value, config.language)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "City":
                        if (!validators.validateCityName(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "BirthDate":
                        if (
                            !input.value || new Date(input.value) > this.maxDate() ||
                            input.value === "Invalid date"
                        ) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    default:
                        break;
                }
            } else {
                if (input.value) {
                    if (!validators.validateStreetNo(input.value)) {
                        isValid = false;
                        inputs[index].error = true;
                    } else {
                        inputs[index].error = false;
                    }
                } else {
                    inputs[index].error = false;
                }
            }
        });

        this.setState({inputs: inputs});

        return isValid;
    };

    validateCheckboxes = () => {
        let checkboxes = [...this.state.checkboxes];
        let isValid = true;

        // eslint-disable-next-line array-callback-return
        checkboxes.map((checkbox, index) => {
            if (checkbox.required) {
                checkboxes[index].error = !document.getElementById(checkbox.id)
                    .checked;
                isValid = document.getElementById(checkbox.id).checked;
            } else {
                checkboxes[index].error = false;
            }
        });

        this.setState({checkboxes: checkboxes});
        return isValid;
    };

    prepareDataToSend = () => {
        const inputs = this.state.inputs;
        const checkboxes = this.state.checkboxes;
        let requestJson = {
            src_r: this.state.src_r,
            recaptchaToken: this.state.recaptchaToken || null,
            frstNm: "frstNm", // First name
            lastNm: "lastNm", // Last name
            emailAddrTxt: "emailAddrTxt", // Email
            mobilePhoneNr: "mobilePhoneNr", // Mobile phone number
            rcrtrId: this.state.uplineEmail, // uplineEmail
            apptSrceCd: this.state.appSrcCode, // Application source code
            utmTerm: this.state.utm_term,
            addresses: [
                {
                    addrLocTyp: "HOME",
                    postCd: "postCd", // Post Code
                    city: "city", // City
                    strAddr1Txt: "", // Street
                    strAddr2Txt: "", // Apartment Number
                    strAddr3Txt: "" // Apartment Number
                }
            ],
            agrmnts: [
                {
                    agrmntTyp: "agrmntInd",
                    agrmntVerNr: "1",
                    agrmntAcptdInd: false
                },
                {
                    agrmntTyp: "prsnlDataStorgInd",
                    agrmntVerNr: "1",
                    agrmntAcptdInd: false
                },
                {
                    agrmntTyp: "prvcyAgrmntInd",
                    agrmntVerNr: "1",
                    agrmntAcptdInd: false
                }
            ],
            cmnctn: [
                {
                    comnctnTyp: "ALWD",
                    email: false,
                    phone: false,
                    sms: false
                }
            ]
        };

        inputs.map(input => {
            if (input.id === "firstName") {
                requestJson.frstNm = input.value;
            }

            if (input.id === "BirthDate") {
                requestJson.birthDate = input.value;
            }

            if (input.id === "lastName") {
                requestJson.lastNm = input.value;
            }

            if (input.id === "Email") {
                requestJson.emailAddrTxt = input.value;
            }

            if (input.id === "Phone") {
                requestJson.mobilePhoneNr =
                    conf.prefix + input.value.replace(/ /g, "");
            }

            if (input.id === "Street") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].strAddr1Txt = input.value;
                });
            }

            if (input.id === "StreetNr") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].strAddr2Txt = input.value;
                });
            }

            if (input.id === "ApartmentNr") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].strAddr3Txt = input.value;
                });
            }

            if (input.id === "PostCode") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].postCd = input.value;
                });
            }

            if (input.id === "City") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].city = input.value;
                });
            }
        });

        checkboxes.map(checkbox => {
            if (checkbox.id === "Agreement1") {
                requestJson.agrmnts.map((agreement, i) => {
                    requestJson.agrmnts[i].agrmntAcptdInd = checkbox.isChecked;
                });
            }

            if (checkbox.id === "Agreement2") {
                requestJson.cmnctn.map((agreement, i) => {
                    requestJson.cmnctn[i].email = checkbox.isChecked;
                    requestJson.cmnctn[i].phone = checkbox.isChecked;
                    requestJson.cmnctn[i].sms = checkbox.isChecked;
                });
            }
        });

        return requestJson;
    };

    dateToSendParser = (date) => {

        const y = date.getFullYear();
        const m = date.getMonth() + 1;
        const d = date.getDate();
        const mm = m < 10 ? "0" + m : m;
        const dd = d < 10 ? "0" + d : d;

        return `${y}-${mm}-${dd}`;
    };

    submitHandler = event => {
        event.preventDefault();

        if (this.validateForm() && this.validateCheckboxes()) {
            this.setState({isFormSent: true});
            const errorFunc = this.prepareErrorResponse;
            const successFunc = this.prepareSuccessResponse;

            TagManager.dataLayer({
                dataLayer: {
                    event: "formSent"
                }
            });

            axios
                .post(path, this.prepareDataToSend(), {
                    headers: {"Content-Type": "application/json"}
                })
                .then(function (response) {
                    successFunc(response);
                })
                .catch(function (error) {

                    if (!error.response) {
                        errorFunc();
                    } else {
                        errorFunc(error.response.data.errCd);
                    }
                });
        } else {
            console.log("form is not valid");
        }
    };

    prepareErrorResponse = code => {
        const {t} = this.props;
        const inputs = this.state.inputs;

        switch (code) {
            case "DUPLICITY_REP_EMAIL":
                inputs.map((input, i) => {
                    if (input.id === "Email") {
                        inputs[i].error = true;
                        inputs[i].errorMessage = this.props.t("duplicity_rep_email");
                    }
                });
                break;
            case "DUPLICITY_REP_MOBILE":
                inputs.map((input, i) => {
                    if (input.id === "Phone") {
                        inputs[i].error = true;
                        inputs[i].errorMessage = this.props.t("duplicity_rep_mobile");
                    }
                });
                break;
            case "MULTIPLE_ACCOUNTS_NOT_REAPPOINTABLE":
            case "DUPLICITY_APPOINTMENT_NOT_POSSIBLE":
            case
            "DUPLICITY_REINSTATABLE_REPRESENTATIVE":
                this.setState({duplicityError: true});
                this.setState({error: true});
                break;
            default:
                this.setState({error: true});
                break;
        }

        this.setState({inputs: inputs});
        this.setState({isFormSent: false});
    };

    prepareSuccessResponse = response => {
        this.setState({responseSuccess: true});
        this.props.success();

        TagManager.dataLayer({
            dataLayer: {
                event: "accountCreated",
                    id: response.data.id,
                    apptId: response.data.apptId
            }
        });
    };

    changeInputHandler = (event, id) => {
        const inputIndex = this.state.inputs.findIndex(i => {
            return i.id === id;
        });

        const input = {
            ...this.state.inputs[inputIndex]
        };

        switch (input.id) {
            case "firstName":
            case "lastName":
                if (!validators.validateNameValue(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;

            case "Email":
                if (!validators.validateEmail(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "Street":
                if (!validators.validateStreetName(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "StreetNr":
                if (!validators.validateStreetNo(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "ApartmentNr":
                if (!validators.validateStreetNo(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "City":
                if (!validators.validateCityName(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "PostCode":
                const notValidPostCode = !validators.validatePostCode(event.target.value, config.language);
                let eValue = event.target.value;

                if (config.language == "cz") {
                    if (eValue.length <= 5 && notValidPostCode) {
                        input.error = true;
                        input.class = "errorBg";
                        input.correct = false;
                        input.value = event.target.value;
                    } else if (eValue.length > 5) {
                        return;
                    } else {
                        input.error = false;
                        input.class = "correct";
                        input.correct = true;
                        if (!notValidPostCode) {
                            input.value = event.target.value;
                        }
                    }
                } else {
                    /* Default PL langage */
                    if (eValue.length > 2 && eValue.indexOf("-") === -1) {
                        String.prototype.splice = function (idx, rem, str) {
                            return (
                                this.slice(0, idx) +
                                str +
                                this.slice(idx + Math.abs(rem))
                            );
                        };
                        input.value = eValue.splice(2, 0, "-");
                    } else if (eValue.length <= 6 && notValidPostCode) {
                        input.error = true;
                        input.class = "errorBg";
                        input.correct = false;
                        input.value = event.target.value;
                    } else if (eValue.length > 6) {
                        return;
                    } else {
                        input.error = false;
                        input.class = "correct";
                        input.correct = true;
                        if (!notValidPostCode) {
                            input.value = event.target.value;
                        }
                    }
                }
                break;

            case "Phone":
                const notValid = !validators.validatePhone(event.target.value);
                if (notValid && event.target.value.length <= 9) {
                    input.value = event.target.value;
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                    if (!notValid) {
                        input.value = event.target.value;
                    }
                }
                break;
            default:
                input.value = event.target.value;
                break;
        }

        const inputs = [...this.state.inputs];
        inputs[inputIndex] = input;

        this.setState({inputs: inputs});
    };

    changeCheckboxHandler = (event, id) => {
        const checkboxIndex = this.state.checkboxes.findIndex(i => {
            return i.id === id;
        });

        const checkbox = {
            ...this.state.checkboxes[checkboxIndex]
        };

        if (checkbox.isChecked) {
            checkbox.isChecked = false;
            if (checkbox.id === "Agreement1") {
                checkbox.error = true;
            }
        } else {
            checkbox.isChecked = true;
            checkbox.error = false;
        }
        const checkboxes = [...this.state.checkboxes];
        checkboxes[checkboxIndex] = checkbox;

        this.setState({checkboxes: checkboxes});
    };

    dateHandler = (date, id) => {
        const inputIndex = this.state.inputs.findIndex(i => {
            return i.id === id;
        });

        const input = {
            ...this.state.inputs[inputIndex]
        };

        input.startDate = date;
        input.value = date;

        if (
            (input.value && new Date(input.value) > this.maxDate()) ||
            input.value === "Invalid date"
        ) {
            input.error = true;
            input.class = "errorBg";
            input.correct = false;
        } else if (input.value != null) {
            input.error = false;
            input.class = "correct";
            input.correct = true;
        }

        input.startDate = date;
        input.value = date;

        const inputs = [...this.state.inputs];
        inputs[inputIndex] = input;

        this.setState({inputs: inputs});
    };

    verifyCallback = (recaptchaToken) => {
        this.setState({recaptchaToken: recaptchaToken});
    };

    render() {
        let inputs = this.state.inputs.map(input => {
            const handler = input.id !== "BirthDate" ? event => this.changeInputHandler(event, input.id) :
                (date) => this.dateHandler(date, input.id);
            return (
                <Input
                    input={input}
                    key={input.id}
                    changed={handler}
                />
            );
        });

        let agreements = this.state.checkboxes.map(checkbox => {
            if (checkbox.id == "Agreement1") {
                return <Agreement1
                    key={checkbox.id}
                    input={checkbox}
                    changed={event =>
                        this.changeCheckboxHandler(
                            event,
                            checkbox.id
                        )
                    }
                />;

            }
            if (checkbox.id == "Agreement2") {
                return <Agreement2
                    key={checkbox.id}
                    input={checkbox}
                    changed={event =>
                        this.changeCheckboxHandler(
                            event,
                            checkbox.id
                        )
                    }
                />;
            }
        });

        const isHeader = this.props.t("join_to_avon") ? true : false;
        const renderHeader = () => {
            if (isHeader) {
                return <h1>
                    {this.props.t("join_to_avon")}
                    <br/>
                    <span style={{
                        fontSize: "0.7em",
                        fontFamily: "zona_pro",
                        fontWeight: "normal"
                    }}>
                    {this.props.t("benefits_of_consultants")}
                    </span>
                </h1>;
            }
        };
        return (
            <div className="form-container">
                <ReCaptcha
                    sitekey={process.env.REACT_APP_API_RE_CAPTCHA_KEY}
                    action='form_action'
                    verifyCallback={this.verifyCallback}
                />
                {this.state.responseSuccess ? (
                    <ThankYou/>
                ) : (
                    <div>
                        {this.state.error ? (
                            this.state.duplicityError ? null : (
                                <div style={{color: "black"}}>
                                    {this.props.t("processing_error")}
                                </div>
                            )
                        ) : (
                            renderHeader()
                        )}
                        {this.state.duplicityError ? (
                            <DuplicityError/>
                        ) : (
                            <form
                                name="consultantForm"
                                id="consultantForm"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between"
                                }}
                                onSubmit={this.submitHandler}
                            >
                                {inputs}

                                {/* <Autocomplete
                                            suggestions={[
                                            "05-500",
                                            "22-123",
                                            "15-991",
                                            "12-298",
                                            "Eggs",
                                            "Jaws",
                                            "Reptile",
                                            "Solitary",
                                            "Tail",
                                            "Wetlands"
                                            ]}
                                        /> */}

                                {agreements}

                                {this.state.isFormSent ? (
                                    <div>
                                        {this.props.t("processing_request")}
                                    </div>
                                ) : (
                                    <div>
                                        <button type="submit">
                                            {this.props.t("btn_join_to_avon")}
                                        </button>
                                    </div>
                                )}
                            </form>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const MyForm = withTranslation()(Form);

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
    return (
        <Suspense fallback="loading">
            <MyForm/>
        </Suspense>
    );
}