import React, { useState, Suspense } from "react";
import Icon from "./Icon/Icon";
import styles from "./content.module.css";
import { useTranslation, Trans } from 'react-i18next';

const Content = () => {
    const { t, i18n } = useTranslation();

    const [iconsState] = useState({
        icons: [
            {
                src: require("../../../assets/images/dolacz-do-klubu/icon-1-white.png"),
                text: t("15_percent_discount")
            },
            {
                src: require("../../../assets/images/dolacz-do-klubu/icon-2-white.png"),
                text: t("free_registration")
            },
            {
                src: require("../../../assets/images/dolacz-do-klubu/icon-3-white.png"),
                text: t("first_order_gift") 
            },
            {
                src: require("../../../assets/images/dolacz-do-klubu/icon-4-white.png"),
                text: t("prizes")
            }
        ]
    });

    let iconsToShow = iconsState.icons.map(icon => {
        return <Icon src={icon.src} text={icon.text} key={icon.src} />;
    });

    return (
        <div className={styles.container}>
            <div className={[styles.desktop, styles.text]}>
                <h1>{t("take_advantage")}<br />{t("special_occastions")}</h1>
            </div>
            <div className={styles.icons_box}>
                <div className={styles.icons_row}>{iconsToShow}</div>
            </div>
        </div>
    );
};

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
    return (
      <Suspense fallback="loading">
        <Content />
      </Suspense>
    );
  }