import React, { Suspense } from "react";
import { useTranslation, Trans } from 'react-i18next';
import conf from '../../../config/config'

const Agreement1 = data => {
    const { t, i18n } = useTranslation('', { useSuspense: false });
    return (
        <div className={"agreement"}>
            <label className="container">
                <input
                    type="checkbox"
                    id={data.input.id}
                    name={data.input.name}
                    style={{ visibility: "hidden" }}
                />
                <span
                    className="checkmark" 
                    id={`${data.input.id}checkmark`}
                    name={data.input.name}
                    onClick={data.changed}
                ></span>
                <span className="" onClick={data.changed}>
                    {t("agreement1.accept")}{" "}
                    <a
                        href={conf.agreement.privacy_policy}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("agreement1.privacy_policy")} 
                    </a>{" "}
                    {t("agreement1.and")}{" "}
                    <a
                        href={conf.agreement.terms_of_service}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("agreement1.terms_of_service")}
                    </a>
                </span>
                {data.input.error ? (
                    <div className="error">{data.input.errorMessage}</div>
                ) : null}
            </label>
        </div>
    );
};

export default Agreement1;
