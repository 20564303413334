const config = {};

config.language = 'cz';
config.prefix = '+420';
config.agreement = {
    privacy_policy: "https://www2.avoncosmetics.cz/cz-home/pages/avon-dohoda",
    terms_of_service: "https://www2.avoncosmetics.cz/cz-home/pages/prohlaseni-o-ochrane-osobnich-udaju"
};


export default config;
