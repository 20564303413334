import React from "react";
import { useTranslation, Trans } from 'react-i18next';
import conf from '../../../config/config'

const Agreement2 = data => {
    const { t, i18n } = useTranslation('', { useSuspense: false });
    return (
        <div className={"agreement"}>
            <label className="container">
                <input
                    type="checkbox"
                    id={data.input.id}
                    name={data.input.name}
                />
                <span
                    className="checkmark" 
                    id={`${data.input.id}checkmark`}
                    name={data.input.name}
                    onClick={data.changed}
                ></span>
                <span className="" onClick={data.changed}>
                    {t("agreement2.txt1")}
                    <a
                        href={conf.agreement.privacy_policy}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {" "}
                        {t("agreement2.txt2")}
                    </a>
                </span>
                {data.input.error ? (
                    <div className="error">{t(data.input.errorMessage)}</div>
                ) : null}
            </label>
        </div>
    );
};

export default Agreement2;
