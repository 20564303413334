import React, { Component, Suspense } from "react";
import "./thankYou.css";
import { withTranslation } from 'react-i18next';

class ThankYou extends Component {
    componentDidMount() {}

    render() {
        return (
            <div className="main">
                <div className="container-content">
                    <div className="text-box">
                        <h1>{this.props.t("thankyou.header")}</h1>
                        <h3>{this.props.t("thankyou.confirmation")}</h3>
                    </div>
                    <div className="img-box">
                        {/*TODO: white/dark icon version should depends on language*/}
                        <img src={require("../../assets/images/dolacz-do-klubu/success-icon-dark.png")} alt="nagrody" />
                    </div>
                    <div className="bottom-text-box">
                        <h3>{this.props.t("thankyou.info")}</h3>
                        <a
                            target="_parent"
                            href={this.props.t("thankyou.url")}>
                            <button className="btn-cstm">
                            {this.props.t("thankyou.url_label")}
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

const MyThankYou = withTranslation()(ThankYou)

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
  return (
    <Suspense fallback="loading">
      <MyThankYou />
    </Suspense>
  );
}
