import React from "react"
import style from "./Icon.module.css"

const Icon = (icon) => {

    return (
        <div className={style.box}>
            <img src={icon.src} alt=""/>
            <div className={style.text}>
                <p>{icon.text}</p>
            </div>
        </div>
    );
};

export default Icon;
