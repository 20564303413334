const formConfig = {
    inputs: [
        {
            name: "firstName",
            value: "",
            id: "firstName",
            type: "text",
            error: false,
            errorMessage: "firstname.error",
            correct: null,
            required: true,
            label: "firstname.label",
            placeholder: ""
        },
        {
            name: "lastName",
            value: "",
            id: "lastName",
            type: "text",
            error: false,
            errorMessage: 'lastname.error',
            correct: null,
            required: true,
            label: 'lastname.label',
            placeholder: ""
        },
        {
            name: "email",
            value: "",
            id: "Email",
            type: "email",
            error: false,
            errorMessage: "email.error",
            correct: null,
            required: true,
            label: "email.label",
            placeholder: ""
        },
        {
            name: "phone",
            value: "",
            id: "Phone",
            type: "number",
            error: false,
            errorMessage: "mobile_phone.error",
            correct: null,
            required: true,
            label: "mobile_phone.label",
            divClasses: "phone",
            placeholder: ""
        },
        {
            name: "birthDate",
            value: "",
            id: "BirthDate",
            type: "date",
            error: false,
            errorMessage: "birthdate.error",
            correct: null,
            required: true,
            label: "birthdate.label",
            divClasses: "birth-date",
            placeholder: "dd.mm.yyyy"
        },
        // {
        //     name: "street",
        //     value: "",
        //     id: "Street",
        //     type: "text",
        //     error: false,
        //     errorMessage: "street.error",
        //     correct: null,
        //     required: true,
        //     label: "street.label",
        //     divClasses: "street",
        //     placeholder: ""
        // },
        // {
        //     name: "streetNr",
        //     value: "",
        //     id: "StreetNr",
        //     type: "text",
        //     error: false,
        //     errorMessage: "street_no.error",
        //     correct: null,
        //     required: true,
        //     label: "street_no.label",
        //     divClasses: "street-no",
        //     placeholder: ""
        // },
        // {
        //     name: "apartmentNr",
        //     value: "",
        //     id: "ApartmentNr",
        //     type: "text",
        //     error: false,
        //     errorMessage: "apartment_no.error",
        //     correct: null,
        //     required: false,
        //     label: "apartment_no.label",
        //     divClasses: "apartment-no",
        //     placeholder: ""
        // },
        {
            name: "postCode",
            value: "",
            id: "PostCode",
            type: "text",
            error: false,
            errorMessage: "postcode.error",
            correct: null,
            required: true,
            label: "postcode.label",
            divClasses: "post-code",
            placeholder: "xxxxx"
        },
        {
            name: "City",
            value: "",
            id: "City",
            type: "text",
            error: false,
            errorMessage: "city.error",
            correct: null,
            required: true,
            label: "city.label",
            divClasses: "city",
            placeholder: ""
        }
    ],
    checkboxes: [
        {
            name: "Agreement1",
            id: "Agreement1",
            type: "checkbox",
            error: false,
            errorMessage: "agreement.label",
            required: true,
            isChecked: false
        },
        // {
        //     name: "Agreement2",
        //     id: "Agreement2",
        //     type: "checkbox",
        //     error: false,
        //     errorMessage: "agreement.label",
        //     required: false,
        //     isChecked: false
        // }
    ],
    validations: {
        postCode: "/^[0-9]{5}$/"
    }
};

export default formConfig;