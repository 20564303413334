import React, {useState} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import pl from "date-fns/locale/pl";
import DatePickerDropdown from "../DatePickerDropdown/DatePickerDropdown";

import "react-datepicker/dist/react-datepicker.css";
import CSS from "./Input.module.css";
import {useTranslation, Trans} from "react-i18next";
import axios from "axios";

const POST_CODE = "PostCode";

const getZipCodes = async (value) => {
    try {

        const res = await axios.get(`https://apim-ce.avon.com/v1/CZ/CS/geo/zip?geoNm=${value}`);
        const codes = await new Set();
        await res.data.forEach((value) => {
            codes.add(value.geo4.name);
        });
        return [...codes];
    } catch (e) {
        return [];
    }
};

const Input = ({changed, input: {divClasses, id, label, type, name, className, value, placeholder, error, errorMessage, correct}}) => {
    const {t, i18n} = useTranslation("", {useSuspense: false});
    const [codes, setCodesList] = useState([]);

    const listClickHandler = (e, val) => {
        e.target.value = val;
        setCodesList([]);

        changed(e);
    };

    const changeHandler = (e, id) => {

        if (id === POST_CODE && e.target.value.length < 5) {
            getZipCodes(e.target.value).then(res => setCodesList(res));
        }
        changed(e);
    };

    const focusHandler = (e, id) => {
        if (id === POST_CODE && e.target.value.length <= 5) {
            getZipCodes(e.target.value).then(res => setCodesList(res));
        }
    };

    const blurHandler = (id) => {

        if (id === POST_CODE) {
            setTimeout(() => setCodesList([]), 200)
            }
    };

    const zipCodes = codes && codes.map(code => (
        <p key={code} onClick={(e) => listClickHandler(e, code)}>{code}</p>));

    return (
        <div
            className={
                divClasses ? divClasses + " flex" : "flex width-100"
            }
        >
            <label className={CSS.textInput + " text-input width-100"}>
                <p>{t(label)}</p>
                {id === "BirthDate" ? <div>
                        <DatePickerDropdown dateChange={changed}/>
                        <input
                            readOnly
                            type={type}
                            name={name}
                            id="BirthDate"
                            className={className}
                            value={value}
                        />
                    </div> :
                    <input
                        type={type}
                        name={name}
                        id={id}
                        className={className}
                        value={value}
                        onChange={(e) => changeHandler(e, id)}
                        placeholder={placeholder}
                        onFocus={(e) => focusHandler(e, id)}
                        onBlur={() => blurHandler(id)}
                    />}
                {id === POST_CODE && codes.length > 0 && <div className={CSS.zipBox}>{zipCodes}</div>}
                {error ? (
                    <div className="error"> {value === "Invalid date"
                        ? t("please_select_the_correct_date")
                        : t(errorMessage)}
                    </div>
                ) : null}
                {correct ? (
                    <span className={CSS.correct}/>
                ) : null}
            </label>
        </div>
    );
};

export default Input;
