import React, { Component } from "react";
import Form from "../Form/Form";
import Content from "./Content/Content";
import { loadReCaptcha } from 'react-recaptcha-v3'


import style from "./ddk.module.css";
import trackingCodes from "../../services/trackingCodes";

class DDK extends Component {
    componentDidMount() {
        document.title = "Dołącz do Klubu";
        // trackingCodes.all();

        loadReCaptcha(process.env.REACT_APP_API_RE_CAPTCHA_KEY);
    }

    state = {
        isForm: true
    };

    successHandler = () => {
        this.setState({ isForm: false });
    };

    render() {
        return (
            <div className={style.main}>
                <Content />
                <Form success={() => this.successHandler()} />
            </div>
        );
    }
}

export default DDK;
