import React from "react";
import { Route } from "react-router-dom";
import "./App.css";
import "../assets/fonts/zona-font.css";
import DDK from "../components/Dolacz-do-klubu/DDK";
import Selfappointment from "../components/Selfappointment/Selfappointment";

function App() {
    return (
        <div className={"app"}>
            <Route exact path="/" component={DDK} />
            <Route exact path="/dolacz-do-klubu" component={DDK} />
            <Route exact path="/selfappointment" component={Selfappointment} />
        </div>
    );
}

export default App;
