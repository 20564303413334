import React from "react";
export default class DuplicityError extends React.Component {
    render() {
        return (
            <div className="main" style={{ height: "100%" }}>
                <div
                    className="container-content"
                    style={{ justifyContent: "center" }}
                >
                    <div className="text-box">
                        <h2>Twoje konto już istnieje.</h2>
                    </div>
                    <div className={"text-box"}>
                        <h4>
                            Jeśli nie pamiętasz hasła, kliknij "Nie pamiętam
                            hasła" <br />
                            po przejściu na stronę logowania.
                        </h4>
                    </div>
                    <div
                        className="bottom-text-box"
                        style={{ marginTop: "15%" }}
                    >
                        <a
                            target="_parent"
                            href="https://www2.pl.avon.com/pl-home"
                        >
                            <button className="btn-cstm">
                                PRZEJDŹ DO STRONY LOGOWANIA
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
