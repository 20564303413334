export const validateNameValue = (name, language) => {
    const reg = /^[A-Za-zěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮ\s]{2,}[-]?[A-Za-zěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮ\s]*[-.]?[A-Za-zěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮ\s]*$/;;
    
    if(language == 'pl') {
        reg = /^[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź\s]{2,}[-]?[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź\s]*[-.]?[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź\s]*$/;
    }
    return name.match(reg);
};

export const validateEmail = email => {
    const reg = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

    return email.match(reg);
};

export const validatePhone = phone => {
    const reg = /^[0-9]{9}$/;

    return phone.match(reg);
};

export const validateStreetName = (street, language) => {
    const reg = /^[0-9A-Za-zěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮ\s]{3,100}[.]?[\s]?$|^[A-Za-zěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮ]+[A-Za-zěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮ0-9\s\-.]+[A-Za-zěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮ0-9]{1,100}[\s]?$/;
    if(language == 'pl') {
        reg = /^[0-9A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź\s]{3,100}[.]?[\s]?$|^[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź]+[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź0-9\s\-.]+[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź0-9]{1,100}[\s]?$/;
    }
    return street.match(reg);
};

export const validateCityName = (city, language) => {
    const reg = /^[A-Za-zěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮ]{3,100}$|^[A-Za-zěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮ]+[A-Za-zěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮ0-9\s-]+[A-Za-zěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮ0-9]{1,100}[\s]?$/;
    if(language == 'pl') {
        reg = /^[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź]{3,100}$|^[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź]+[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź0-9\s-]+[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź0-9]{1,100}[\s]?$/;
    }
    return city.match(reg);
};

export const validatePostCode = (postCode, language) => {
    if(language == 'cz') {
        return postCode.match(/^[1-7][0-9]{4}$/);
        
    } else {
        /* Default PL language */
        return postCode.match(/^[0-9]{2}-[0-9]{3}$/);
    }
};

export const validateStreetNo = number => {
    return number.match(/^[0-9]{1,9}[A-Za-z]{0,9}[\/]?[\s]?[A-Za-z0-9\s]{0,5}$/);
};
