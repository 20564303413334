import React, { Component } from "react";
import Form from "../Form/Form";

// TODO: load styles depend on language
import styles from "./cz.module.css";

class Selfappointment extends Component {
    render() {
        return <Form />;
    }
}

export default Selfappointment;
